:root {
  --PrimaryButtonBg_firstColor: #0354fe;
  --PrimaryButtonBg_secondColor: #13a9fa;
  --PrimaryButtonBg: linear-gradient(
    157deg,
    var(--PrimaryButtonBg_firstColor),
    var(--PrimaryButtonBg_secondColor)
  );
  --PrimaryButtonHoverBg: linear-gradient(
    157deg,
    var(--PrimaryButtonBg_secondColor),
    var(--PrimaryButtonBg_secondColor)
  );
  --buttonLoadingBg: linear-gradient(
    157deg,
    var(--PrimaryButtonBg_firstColor),
    var(--PrimaryButtonBg_firstColor)
  );
  --buttonSuccessBg: linear-gradient(
    157deg,
    var(--successButtonBg),
    var(--successButtonBg)
  );
  --buttonErrorBg: linear-gradient(
    157deg,
    var(--errorButtonBg),
    var(--errorButtonBg)
  );
  --defaultButtonBg: transparent;
  --defaultButtonColor: #242c41;
  --successButtonBg: #00b17a;
  --errorButtonBg: #d00027;
  --inputFocusColor: #5481f7;
  --inputColor: #242c41;
  --danger: #e76b80;
}

@font-face {
  font-family: 'Ubuntu';
  src: /*url('/fonts/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),*/ url('/fonts/Ubuntu-Medium.ttf')
      format('truetype'),
    url('/fonts/Ubuntu-Medium.svg#Ubuntu') format('svg');
  font-weight: normal;
  font-style: normal;
}

*,
*:after,
*:before {
  transition-duration: 0.3s;
}

html,
body {
  font-family: 'Ubuntu';
  transition-duration: unset;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #242c41;
}

a,
a:hover {
  color: inherit;
  text-decoration: none;
}

.App__Wrapper {
  display: flex;
  min-height: 100%;
  width: 100%;
}

.App__Container {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-left: 120px;
  min-height: 100vh;
  max-width: 100%;
}
.HiddenNav {
  padding-left: 0;
}

.App__Header {
  position: fixed;
  width: calc(100% - 120px);
  top: 0;
  right: 0;
  z-index: 8 !important;
  border-bottom: 1px solid #caced5;
  background-color: #fff;
}

.App__Header .App__Header__Container {
  overflow: auto;
  left: 0;
  right: 0;
  z-index: 10 !important;

  display: flex;
  align-items: center;
}

.User-Nav-Container {
  // display: flex;
  align-items: center;
  // position: relative;
  z-index: 1;
  // float: right;
}
.User__Nav {
  display: flex;
}
.User__Image {
  width: 30px;
  height: 30px;
  margin: 0 30px;
}
.User__Name {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
  // line-height: 63px;
}
.User__Menu {
  position: absolute;
  right: 5px;
  top: 50px;
  min-width: 160px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  border: 1px solid #8992a5;
  border-radius: 3px;
  z-index: 5;
}

.User__Menu .USelect__Item:first-child span {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 0 15px 19px;
}

.App__Layout {
  flex-grow: 1;
  width: 100%;
  position: relative;
  z-index: 6;
  margin-top: 63px;
}
.HiddenHeader {
  margin-top: 0;
}

/* Page --> */
img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.PageContent__Container {
  padding: 10px 50px;
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
}

/* .PageContent__Container.row>div {
    margin-bottom: 50px;
} */
/* .Chart__Wrapper.bordered-content{
    min-height: auto;
} */
.bordered-content .Chart__Wrapper {
  margin-bottom: 0;
}
.Chart__Wrapper {
  border-radius: 3px;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.Chart__Wrapper.heightAuto {
  border: none;
  overflow: hidden;
}
.Chart__Wrapper.row {
  margin: 0;
}
/* .Chart__Wrapper>div {
    height: 100%;
} */

.Chart__Wrapper.Column__Chart {
  height: auto;
  min-height: 320px;
}
.Chart__Wrapper.Column__Chart .row {
  flex-direction: column;
  align-items: center;
}
.Chart__Wrapper.Column__Chart div {
  max-width: inherit;
  margin: 0 auto;
  padding: 0;
}
.Chart__Wrapper.Column__Chart .Border__Left {
  border-top: 1px solid #caced5;
}
.Chart__Wrapper.Column__Chart .Border__Left:after,
.Chart__Wrapper.Column__Chart .Border__Right:after {
  display: none;
}
.recharts-responsive-container {
  min-height: 325px;
}

.bordered-content {
  border: solid 1px #caced5;
  min-height: 270px;
  width: 100%;
}

.bordered {
  border: solid 1px #caced5;
}
%weekdaysBarBefore {
  content: '';
  display: block;
  position: absolute;
  background-color: #5481f7;
  width: 5px;
  height: 10px;
  top: -2px;
  left: 8px;
}
%weekendsBarAfter {
  content: '';
  display: block;
  position: absolute;
  background-color: #ffc106;
  width: 5px;
  height: 15px;
  top: -7px;
  left: 15px;
}
%centerAbsoluteBar {
  left: 50%;
  transform: translate(-50%, 0);
}
.total-val-chart {
  .weekFilter {
    // background-color: var(--PrimaryButtonBg_firstColor);
    display: inline-block;
    margin-right: 5px;
    vertical-align: text-top;
    & > * {
      display: inline-block;
      margin: 3px;
      cursor: pointer;
      &:not(.active) {
        opacity: 0.7;
      }
      &:hover {
        opacity: 1;
      }
    }

    .week {
      &:before {
        @extend %weekdaysBarBefore;
      }
      &:after {
        @extend %weekendsBarAfter;
      }
      // background-color: green;
      padding-top: 8px;
      font-size: 0.5rem;
      position: relative;
    }
    .weekdays {
      &:before {
        @extend %weekdaysBarBefore;
        @extend %centerAbsoluteBar;
      }
      // background-color: blue;
      padding-top: 8px;
      font-size: 0.5rem;
      position: relative;
    }
    .weekends {
      &:after {
        @extend %weekendsBarAfter;
        @extend %centerAbsoluteBar;
      }
      // background-color: orange;
      padding-top: 8px;
      font-size: 0.5rem;
      position: relative;
    }
  }
}

.additionalInformation {
  display: inline-block;
}
.total-val-chart *,
.total-val-chart *::after,
.total-val-chart *::before {
  transition-duration: 0s;
}
.total-val-chart {
  position: absolute;
  top: 15px;
  right: 10px;
  font-size: 0.8rem;
  z-index: 1;
  cursor: default;
}
.total-val-chart p {
  margin: 0;
}
.total-val-chart .tooltiph {
  position: relative;
  display: inline-block;
  float: right;
  text-decoration: underline;
}

.ttip .ttext {
  border: 1px solid #0245c3;
  visibility: hidden;
  opacity: 1;
  background-color: #ffffff;
  color: #242c41;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  right: 0;
  width: max-content;
  top: 17px;
  padding: 2px 4px;
  height: auto;
  cursor: default;
}

.ttip:hover .ttext {
  visibility: visible;
}
.mh-auto {
  min-height: auto;
}
.PageContent__Title {
  font-family: 'Ubuntu';
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  color: #242c41;
  display: flex;
  justify-content: space-between;
}

.PageContentList__Wrapper {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
  height: 100%;
}
.PageContentList__Wrapper .PageContentList__Item {
  flex-grow: 1;
  display: flex;
}
.PageContentList__Wrapper .PageContentList__Item a {
  padding: 37px 32px;
  font-size: 16px;
  letter-spacing: 0.7px;
  color: #242c41;
  font-family: 'Ubuntu';
  font-weight: 500;
  flex-grow: 1;
  display: flex;
}

.PageContentList__Wrapper .PageContentList__Item:nth-child(even) {
  background-color: #fafbfc;
  border-top: 1px solid #e5e6eb;
  border-bottom: 1px solid #e5e6eb;
}
.UTour__Info__Toggle {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  background-image: url('/icon__info.svg');
  border: 3px solid #e5e6eb;
  text-align: center;
  cursor: pointer;
  z-index: 99;
  padding: 5px;
}
#___reactour {
  .mask {
    opacity: 0.7;
  }
}

/* <-- Page */
#Company .User-Nav-Container {
  height: 66px;
  border-bottom: 1px solid #caced5;
  flex-wrap: wrap;
  flex: 0 0 100%;
  flex-direction: inherit;
}
#Company .SelectStore__List__Wrapper {
  background: #fff;
  padding-bottom: 20px;
  padding-top: 10px;
}

.Company__Logo {
  max-width: 90%;
  max-width: 82px;
  & > * {
    height: 100%;
    width: 100%;
    fill: #fff;
  }
  img {
    // filter: drop-shadow(0px 0px 1px #dddd);
    // box-shadow: 0 5px 15px #aaaaaaba;
    // border-radius: 6px;
  }
}
.Company__Logo svg g {
  fill: #fff;
}

.navigation {
  height: 100vh;
  background-image: linear-gradient(174deg, #0261db, #0132b4);
  box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 9 !important;
  width: 120px;
  &.test-navigation {
    background-image: linear-gradient(174deg, #faa357, #f08629);
  }
  .navigation_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: auto 1fr;
    row-gap: 10px;
    padding-top: 10px;
    .Company__Logo {
      height: 45px;
      // flex: 40px auto;
    }
  }
}

nav div {
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

nav a {
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}

/* Login -> */

.Login__Wrapper {
  width: 100%;
  position: relative;
  display: flex;
}

.Login__Wrapper .Logo__Login {
  position: absolute;
  left: 60px;
  top: 60px;
  width: 125px;
}

.Logo__Login svg {
  width: 100%;
  height: auto;
}

.Login__Layout {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 67%;
  flex-grow: 1;
  background-color: #fff;
}

.LoginForm__Wrapper {
  display: flex;
  flex-wrap: wrap;
  width: 96%;
  max-width: 450px;
  margin-bottom: 10px;
  position: relative;
}

.Login__RightsideBg {
  width: 33%;
  max-width: 446px;
  background-color: #242c41;
  min-height: 100vh;
  background-image: url(/Login_Bg.png);
  background-repeat: no-repeat;
}

.Login__RightsideBg img {
  height: 100%;
  width: 100%;
}
.Login__Form {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.Login__Form .Button__Group {
  display: flex;
  justify-content: space-between;
}
.Button__Group .Primary {
  max-height: 0;
  margin: auto;
}
.Button__Group .uButton.default {
  width: 100%;
  justify-content: flex-end;
}
.Button__Group .uButton.default.forgot {
  padding-bottom: 0;
}
.Button__Group .uButton.default.request {
  padding-top: 0;
}

.Login__Form__Input {
  position: relative;
  margin-bottom: 20px;
  letter-spacing: 0.7px;
}

.Login__Form__Input label {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  color: #8992a5;
  margin: 0;
  cursor: text;
}

.Login__Form__Input input[type='text'],
.Login__Form__Input input[type='password'],
.Login__Form__Input input[type='email'] {
  padding: 20px 10px;
  width: 100%;
  outline: none;
  border: none;
  border-bottom: 1px solid #caced5;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.7px;
  color: var(--inputColor);
}

.Login__Form__Input input[type='text']:focus,
.Login__Form__Input input[type='password']:focus,
.Login__Form__Input input[type='email']:focus {
  color: var(--inputFocusColor);
  border-color: var(--inputFocusColor);
}

.Login__Form__Input input[type='text']:focus + label,
.Login__Form__Input input[type='password']:focus + label,
.Login__Form__Input input[type='email']:focus + label,
.Login__Form__Input input:not([val='']) + label {
  top: -5px;
  left: 0;
  font-size: 0.9rem;
}

.Login__Form__Input input:invalid {
  box-shadow: none;
}

.Login__Form__Input input.invalid {
  border-color: var(--danger);
}

.Login__Form__Input input.invalid,
.Login__Form__Input input.invalid + label {
  color: var(--danger);
}
.SubmitResponse {
  font-size: 0.8rem;
  color: rgb(92, 116, 255);
}

/* <- Login */

/* Input Forms -> */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit;
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
  box-shadow: 0 0 0px 1000px #fff inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
.uploader {
  position: relative;
}
.Input__Group {
  display: flex;
}
.Input__Group > * {
  display: inline-flex !important;
  margin: 5px 15px 30px 0 !important;
}
.Text__Input {
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 13px;
  -webkit-box-shadow: 0 0 5px 0 transparent;
  box-shadow: 0 0 5px 0 transparent;
  line-height: normal;
  font-weight: normal;
}
.Text__Input:focus {
  outline: none;
  box-shadow: 0 1px 13px 0 rgba(86, 119, 202, 0.39);
}

.File__Input,
.csv-input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  visibility: hidden;
}
label {
  display: inline-flex;
  margin-right: 1em;
  align-items: center;
}
input[type='checkbox'],
input[type='radio'] {
  margin-right: 0.5em;
}
input:disabled {
  cursor: not-allowed;
}
/* <- Input Forms */

/* uButton -> */

.uButton {
  font-family: 'Ubuntu';
  outline: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 158px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 14px 21px;
  min-height: 44px;
  position: relative;
  user-select: none;
  text-decoration: none;
  letter-spacing: 0.6px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  box-shadow: 3px 3px 4px 0 rgba(36, 44, 65, 0.19);
  display: inline-flex;
}
.uButton:before {
  border-radius: inherit;
  background-image: linear-gradient(#ccc, #fff);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  transition: opacity 0.45s;
}

.uButton:focus,
.uButton:active {
  outline: none;
}

.uButton .buttontext {
  display: block;
  position: relative;
}
.uButton.loading .buttontext,
.uButton.success .buttontext,
.uButton.error .buttontext {
  opacity: 0;
  transform: translateX(-2px);
  transition-duration: 0.2s;
}

.uButton .icon {
  width: 44px;
  height: 44px;
  display: block;
  border-radius: 50%;
  background-size: contain;
  position: absolute;
  z-index: 2;
  top: 0;
}
.Hamburger_Menu .uButton .icon {
  position: unset;
}

.uButton.success .icon {
  background-image: url('/icons/button-check.svg');
}

.uButton.error .icon {
  background-image: url('/icons/icon__cross.svg');
  width: 30px;
  height: 30px;
  top: unset;
}

.uButton.loading .icon {
  background-image: url('/icons/button-loading.svg');
  animation: loadingIcon cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.8s infinite;
}

@keyframes loadingIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.uButton.default {
  background: var(--defaultButtonBg);
  color: var(--defaultButtonColor);
  border-radius: 0;
  justify-content: center;
  font-family: 'Ubuntu';
  box-shadow: unset;
  box-shadow: none;
}
.uButton.Button__Section {
  color: #caced5;
}

.uButton.default .icon {
  position: relative;
  width: 22px;
  height: 22px;
  margin-right: 30px;
}

.uButton.default:hover,
.uButton.link:hover {
  text-decoration: none;
  opacity: 0.5;
}

.uButton.Primary:before {
  background-image: var(--PrimaryButtonHoverBg);
}
.uButton.Primary {
  background: var(--PrimaryButtonBg);
}

.uButton.Primary:hover:before {
  opacity: 1;
}

.uButton.checked:hover {
  background: var(--successButtonBg);
}

.uButton.success:before {
  background-image: var(--buttonSuccessBg);
  opacity: 1;
}
.uButton.error:before {
  background-image: var(--buttonErrorBg);
  opacity: 1;
}
.uButton.loading:before {
  background-image: var(--buttonLoadingBg);
  opacity: 1;
}
/* USelect */
.css-1492t68 {
  width: calc(100% - 20px);
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* --Uselect */

.uButton.link {
  justify-content: flex-start;
  text-align: left;
  box-shadow: inherit;
}
.link {
  cursor: pointer;
}
.link:hover {
  opacity: 0.6;
}

.Login__Wrapper .Select__Lang__Buttons {
  float: right;
  position: fixed;
  bottom: 0;
  left: 0;
  margin: auto;
  width: calc(100% - 23%);
  text-align: center;
  float: left;
}

.Select__Lang__Buttons {
  // position: relative;
  // float: right;
  padding-top: 5px;
  // display: inline-block;
  // width: 45px;
  // margin-right: 10px;
  z-index: 1;
}
.Select__Lang__Dropdown {
  background: white;
  border: solid 1px #caced5;
  position: absolute;
  top: 50px;
  right: 5px;
  padding: 4px;
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
  z-index: 5;
}
// .Select__Lang__Dropdown::before {
//   content: '';
//   background: white;
//   width: 10px;
//   height: 10px;
//   position: fixed;
//   left: 24px;
//   top: -6px;
//   z-index: 5;
//   display: none;
//   border-left: solid 1px #caced5;
//   border-top: solid 1px #caced5;
//   transform: rotate(45deg);
// }
// .Select__Lang__Dropdown > * {
//   padding: 0 !important;
//   text-align: center;
//   margin: 5px 0;
//   position: fixed;
//   z-index: 5;
// }
.Select__Lang__Dropdown > * .icon {
  margin: 0 !important;
}

// .Select__Lang__Buttons:hover .Select__Lang__Dropdown,
// .Select__Lang__Buttons:hover .Select__Lang__Dropdown::before {
//   display: block;
// }
.Select__Lang__Buttons .uButton.link {
  /*     padding: 0 !important;
 */
  padding: 10px;
  margin-top: 10px;
  margin-right: 30px;
  text-align: center;
}

/* <- uButton */

/* Icons -> */

.icon {
  width: 16px;
  height: 16px;
  text-indent: 18px;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  text-decoration: none;
  transform-origin: 50% 50%;
  cursor: pointer;
  align-self: center;
}

.icon.pasty {
  -moz-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  -o-filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  filter: gray;
  opacity: 0.6;
}

a.icon {
  cursor: pointer;
}

.icon.xs {
  background-size: 12px 12px;
  width: 12px !important;
  height: 12px !important;
  float: left;
  margin-left: 0.6em;
}

.icon.normal {
  width: 16px !important;
  height: 16px !important;
  background-size: 16px 16px;
}

.icon.goBack,
.icon.goNext {
  background-image: url('/icons/icon__back.svg');
}
.icon.goNext {
  transform: rotate(180deg);
}

.icon.home {
  background-image: url('/icons/icon__home.svg');
}

.icon.down,
.icon.prev,
.icon.next {
  background-image: url('/icons/icon__down.svg');
}

.icon.hamburger {
  background-image: url('/icons/icon-hamburger.svg');
  right: 160px;
}

.icon.prev {
  transform: rotate(90deg);
}
.icon.next {
  transform: rotate(-90deg);
}

.icon.toUp {
  background-image: url('/icons/icon__toUp.svg');
}

.icon.toDown {
  background-image: url('/icons/icon__toDown.svg');
}

.icon.calender {
  background-image: url('/icons/icon__calender.png');
  width: 20px;
  height: 20px;
}
.icon.clock2 {
  background-image: url('/icons/icon__clock2.png');
  width: 20px;
  height: 20px;
  cursor: unset;
}

.icon.clock {
  background-image: url('/icons/icon__clock.svg');
  width: 20px;
  height: 20px;
}

.icon.notification {
  background-image: url('/icons/icon__notification.svg');
  width: 20px;
  height: 20px;
}
.icon.cross {
  background-image: url('/icons/icon__delete.png');
  width: 8px;
  height: 8px;
}

.icon.deletecross {
  background-image: url('/icons/icon__cross.svg');
  width: 8px;
  height: 8px;
}
.icon.update {
  background-image: url('/icons/icon__update.png');
}

.icon.search {
  background-image: url('/icons/icon__search.svg');
  width: 18px;
  height: 18px;
}
.icon.download {
  background-image: url('/icons/icon__download.svg');
  width: 24px;
  height: 24px;
}
.icon.flag-tr {
  background-image: url('/icons/icon__flag-tr.svg');
  width: 24px;
  height: 18px;
}
.icon.flag-en {
  background-image: url('/icons/icon__flag-en.svg');
  width: 24px;
  height: 18px;
}
.icon.flag-de {
  background-image: url('/icons/icon__flag-de.svg');
  width: 24px;
  height: 18px;
}
.icon.prevTime {
  background-image: url('/icons/previous__time.svg');
  width: 8px;
  height: 8px;
}
.icon.like {
  background-image: url('/icons/like.svg');
  width: 35px;
  height: 35px;
}
.icon.dislike {
  background-image: url('/icons/dislike.svg');
  width: 35px;
  height: 35px;
}
.icon.questionMark {
  background-image: url('/icons/questionMark.svg');
  width: 35px;
  height: 35px;
}
.icon.undo-arrow {
  background-image: url('/icons/undo-arrow.svg');
  width: 35px;
  height: 35px;
}
.icon.undo-arrow2 {
  background-image: url('/icons/undo-arrow2.svg');
  width: 35px;
  height: 35px;
  transform: rotate(25deg);
  transform-origin: center center;
}
.icon.edit {
  background-image: url('/icons/edit.svg');
  width: 20px;
  height: 20px;
  opacity: 0.5;
  transition-duration: 0s;
}
.icon.edit:hover {
  opacity: 1;
}
.icon.printer {
  background-image: url('/icons/printer.svg');
  width: 35px;
  height: 35px;
  border-radius: unset;
}
.icon.list {
  background-image: url('/icons/list.svg');
  width: 35px;
  height: 35px;
}
.icon.checkout {
  background-image: url('/icons/checkOut.svg');
  width: 35px;
  height: 35px;
}
.icon.camera {
  background-image: url('/icons/camera.svg');
  width: 35px;
  height: 35px;
}
.icon.hideEye {
  background-image: url('/icons/icon_hide.svg');
  width: 25px;
  height: 25px;
}
.icon.photoDownload {
  background-image: url('/icons/icon__photo.svg');
  width: 25px;
  height: 25px;
}
.icon.demography {
  background-image: url('/icons/demography.svg');
  width: 17px;
  height: 17px;
}
.icon.Clear {
  background-image: url('/icons/Clear.svg');
  width: 15px;
  height: 15px;
  margin-left: 5px;
}
.icon.Clouds {
  background-image: url('/icons/Clouds.svg');
  width: 13px;
  height: 13px;
}
.icon.Rain {
  background-image: url('/icons/Rain.svg');
  width: 15px;
  height: 15px;
}
.icon.Fog {
  background-image: url('/icons/Fog.svg');
  width: 15px;
  height: 15px;
}
.icon.Mist {
  background-image: url('/icons/Mist.svg');
  width: 15px;
  height: 15px;
}
.icon.Snow {
  background-image: url('/icons/Snow.svg');
  width: 15px;
  height: 15px;
}
.icon.Haze {
  background-image: url('/icons/Haze.svg');
  width: 15px;
  height: 15px;
}
.icon.Thunderstorm {
  background-image: url('/icons/Thunderstorm.svg');
  width: 15px;
  height: 15px;
}
.icon.linkIcon {
  background-image: url('/icons/icon__link.svg');
  width: 17px;
  height: 17px;
  margin: 0 5px;
}
.icon.personGroup {
  background-image: url('/icons/group.svg');
  width: 80px;
  height: 80px;
  cursor: unset;
}
.icon.setting {
  background-image: url('/icons/icon__setting.svg');
  width: 35px;
  height: 35px;
  cursor: unset;
}
.icon.settingBlack {
  background-image: url('/icons/icon__settingBlack.svg');
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.icon.settingGray {
  background-image: url('/icons/icon_settingGray.svg');
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.icon.editDocument {
  background-image: url('/icons/edit__document.svg');
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.icon.trash {
  background-image: url('/icons/trash__icon.svg');
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.icon.fileUpload {
  background-image: url('/icons/icon__fileUpload.svg');
  width: 32px;
  height: 32px;
  cursor: pointer;
}
.icon.tick {
  background-image: url('/icons/icon__tick.svg');
  width: 18px;
  height: 18px;
}
.icon.recalcution {
  background-image: url('/icons/icon__recalcution.svg');
  width: 35px;
  height: 35px;
}
.icon.checkout_inline {
  background-image: url('/icons/checkout-inline.svg');
  width: 35px;
  height: 35px;
}
.icon.boxIn {
  background-image: url('/icons/boxIn.svg');
  width: 35px;
  height: 35px;
}
.icon.personel {
  background-image: url('/icons/personel.svg');
  width: 35px;
  height: 35px;
}
.icon.personel_black {
  background-image: url('/icons/icon__personnel__black.svg');
  width: 35px;
  height: 35px;
}
.icon.people {
  background-image: url('/icons/people.svg');
  width: 35px;
  height: 35px;
}
.icon.rightArrow {
  background-image: url('/icons/right_arrow.svg');
  width: 14px;
  height: 14px;
}
.ArrowCircle {
  display: inline-flex;
  justify-content: center;
  align-self: center;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  border: 1.5px solid #5481f7;
  box-shadow: 3px 0px 7px 2px rgba(0, 0, 0, 0.12);
}
.LongArrow {
  width: 3px;
  height: 13px;
  background-color: #5481f7;
  position: relative;
  margin-top: 6px;
  margin-left: 4px;
  margin-right: 3px;
}
.LongArrow::before {
  content: ' ';
  transform: rotate(45deg);
  position: absolute;
  width: 7px;
  height: 7px;
  border-top: 2px solid #5481f7;
  border-left: 2px solid #5481f7;
  right: -2px;
  top: 0;
}
.icon.left-arrow {
  background-image: url('/icons/left-arrow.png');
  width: 18px;
  height: 18px;
  float: left;
  margin-top: 4px;
}
.icon.right-arrow {
  background-image: url('/icons/right-arrow.png');
  width: 18px;
  height: 18px;
  margin-top: 4px;
}
.icon.density-deselected {
  background-image: url('/icons/icon-density-deselected.png');
  width: 18px;
  height: 18px;
}
.icon.density-selected {
  background-image: url('/icons/icon-density-selected.png');
  width: 18px;
  height: 18px;
}
.icon.person-deselected {
  background-image: url('/icons/icon-person-deselected.png');
  width: 18px;
  height: 18px;
}
.icon.person-selected {
  background-image: url('/icons/icon-person-selected.png');
  width: 18px;
  height: 18px;
}
.icon.time-deselected {
  background-image: url('/icons/icon-time-deselected.png');
  width: 18px;
  height: 18px;
}
.icon.time-selected {
  background-image: url('/icons/icon-time-selected.png');
  width: 18px;
  height: 18px;
}
.icon.interestRate-deselected {
  background-image: url('/icons/icon-interestRate-deselected.png');
  width: 18px;
  height: 18px;
}
.icon.interestRate-selected {
  background-image: url('/icons/icon-interestRate-selected.png');
  width: 18px;
  height: 18px;
}
.icon.ageGender-deselected {
  background-image: url('/icons/icon__deselected_gender.svg');
  width: 18px;
  height: 18px;
}
.icon.ageGender-selected {
  background-image: url('/icons/icon__gender.svg');
  width: 18px;
  height: 18px;
}
.icon.switchCamera {
  background-image: url('/icons/switch-camera.svg');
  width: 18px;
  height: 18px;
}
.icon.likeButton {
  background-image: url('/icons/likeButton.svg');
  width: 30px;
  height: 24px;
}
.icon.cameraSnapShot {
  background-image: url('/icons/cameraSnapShot.svg');
  width: 18px;
  height: 18px;
}
.icon.minusButton {
  background-image: url('/icons/minus.svg');
  width: 16px;
  height: 16px;
}
.icon.plusButton {
  background-image: url('/icons/plus.svg');
  width: 16px;
  height: 16px;
}
.icon.rotateLeftButton {
  background-image: url('/icons/rotateLeft.svg');
  width: 16px;
  height: 16px;
}
.icon.rotateRightButton {
  background-image: url('/icons/rotate.svg');
  width: 16px;
  height: 16px;
}
.icon.rotate {
  background-image: url('/icons/icon_rotate.svg');
  width: 24px;
  height: 24px;
}
.icon.clock {
  background-image: url('/icons/clock.svg');
  width: 35px;
  height: 35px;
}
.icon.shopping {
  background-image: url('/icons/shoppingpackage.svg');
  width: 35px;
  height: 35px;
}
.icon.clockPeople {
  background-image: url('/icons/clockPeople.svg');
  width: 35px;
  height: 35px;
}
.icon.live {
  background-image: url('/icons/icon__live.svg');
  width: 30px;
  height: 20px;
}
.icon.detail {
  background-image: url('/icons/detail.svg');
  width: 18px;
  height: 18px;
}
.icon.sandclock {
  background-image: url('/icons/sandclock.svg');
  width: 25px;
  height: 22px;
}
.icon.info {
  background-image: url('/icons/info.svg');
  width: 25px;
  height: 22px;
}
/* #newYear {
        background-image: url("/icons/new-year.svg");
        width: 60px;
        height: 60px;
        position: fixed;
        z-index: 90;
        top: 30px;
        left: 120px;
        fill: white;
        animation: skew 1.3s infinite;
        transform: rotate(20deg);
        animation-direction: alternate;
        transform-origin: top;
        pointer-events: none;
    }
    #wnewYear {
        background-image: url("/icons/white-new-year.svg");
        width: 100px;
        height: 100px;
        position: fixed;
        z-index: 90;
        top: 50px;
        right: 40px;
        fill: white;
        animation: skew 1.3s infinite;
        transform: rotate(20deg);
        animation-direction: alternate;
        transform-origin: top;
        pointer-events: none;
    }
     
    @keyframes skew {
        0% {
          transform: rotate(20deg);
        }
        100% {
          transform: rotate(-20deg);
        }
      } */
.Select__Lang__Buttons .uButton.default .icon {
  // margin-right: 5px;
  border-radius: 0;
}
/* <- Icons */

/* Charts --> */

.Chart-wrapper:before {
  content: '';
  top: 50%;
  left: 50%;
  width: 96%;
  height: 90%;
  transform: translate(-50%, -50%);
  position: absolute;
  background: rgba(251, 251, 251, 0.9);
  z-index: 2;
  opacity: 0;
  display: none;
}
.Chart__Download {
  position: absolute;
  right: 0;
  bottom: 100%;
}
.Chart__Download > span {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  float: right;
  padding-top: 10px;
}
.uMulti__Wrapper .Chart__Download {
  bottom: 117%;
}
.recharts-wrapper,
.recharts-wrapper * {
  transition-duration: unset;
}

.recharts-active-dot {
  transition-duration: 0.3s;
}

.recharts-wrapper {
  font-size: 12px;
  letter-spacing: 0.5px;
  color: #525971;
  line-height: normal;
}

.recharts-legend-item-text {
  margin: 0 40px 0 10px;
}
.recharts-legend-item svg.recharts-surface {
  width: 10px;
  height: 10px;
}

.recharts-cartesian-grid-vertical,
line.recharts-cartesian-axis-tick-line {
  display: none;
}

g.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis
  g.recharts-cartesian-axis-ticks
  text {
  fill: darkgray;
  /* powderblue */
}

g.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis
  g.recharts-cartesian-axis-ticks {
  transform: translateY(12px);
}

.CircleProgress__Wrapper {
  width: 100%;
  padding: 65px 0 40px 0;
  margin: 0 auto;
  align-items: center;
  display: flex;
  justify-content: center;
}

.CircleProgress__Wrapper .CircularProgressbar {
  max-width: 219px;
  width: 219px;
  height: 219px;
}

.ArchivedData td {
  opacity: 0.3;
}
.ArchivedDataText {
  color: #8992a5;
}

.ArchivedDataicon::before {
  content: '';
  display: inline-block;
  width: 13px;
  height: 13px;
  background-size: 100%;
  margin: 0 5px 4px 0;

  vertical-align: middle;
  background-image: url('/icons/archived.png');
}

/* <-- Charts */

/* Calender --> */
.rdrDefinedRangesWrapper {
  width: auto !important;
  float: left;
}
.rdrDay {
  flex-basis: calc(14.28%);
}
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfMonth .rdrInRange {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfMonth .rdrInRange {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.rdrStartEdge {
  border-top-left-radius: 1.042em !important;
  border-bottom-left-radius: 1.042em !important;
}
.rdrInRange {
  opacity: 0.6;
}
/* <-- Calender */
.page_information {
  font-size: 0.8rem;
  color: rgb(73, 73, 73);
  margin: 10px 0px;
}
.disabled,
.uButton[disabled] {
  pointer-events: none !important;
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}

/* Table -> */

table {
  width: 100%;
  table-layout: fixed;
  font-family: 'Ubuntu';
}

.tbl-header {
  background-color: #fafbfc;
  border-bottom: 1.2px solid #caced5;
}

.tbl-content {
  width: 100%;
  /* height: 254px; */
  margin-top: 0px;
  padding-bottom: 1px;
}
.tbl-content td {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
th {
  padding: 18px 15px 18px 15px;
  text-align: center;
  font-weight: 500;
  color: #525971;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: initial;
  overflow: hidden;
  line-height: normal;
}
.tbl-content tr:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
.tbl-content tr:nth-child(even) {
  background: #fafbfc;
}
td {
  color: #242c41;
  padding: 15px;
  text-align: center;
  vertical-align: middle;
  font-weight: 300;
  font-size: 14px;
  border-bottom: solid 1px #e5e6eb;
}
td > * {
  width: 100%;
}
.Content__Column {
  position: relative;
  .form-control {
    width: min-content;
    margin: auto;
    height: 30px;
  }
}
.Category__Actions > * {
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.Category__Actions > *:first-child {
  margin-left: 0px;
}
.Category__Actions > *:last-child {
  margin-left: auto;
}
.action_columns {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  .edit {
    vertical-align: top;
  }
}
/* <- Table */

.Border__Right,
.Border__Left {
  position: relative;
}
.Border__Right {
  padding-right: 0;
}
.Border__Left {
  padding-left: 0;
}

.Border__Right:after,
.Border__Left:after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  border-right: 1px solid #caced5;
}

.Border__Left:after {
  left: 0;
  right: auto;
}

.Border__Bottom {
  border-bottom: 1px solid #caced5;
}

.p__10 {
  padding: 10px;
  margin: 20px auto;
  border-radius: 3px;
}

.p__30 {
  padding: 30px;
  margin: 20px auto;
  border-radius: 3px;
}
.CameraPhotoWrapper,
.Area__Image {
  position: relative;
  display: flex;
  margin-bottom: 30px;
}

.CameraPhotoWrapper img {
  border-radius: 3px;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.Area__Image {
  position: relative;
}
.Area__Image img {
  opacity: 1;
}
.Area__Image #Rect__Modal-Camera {
  display: none;
}
.CameraPhotoWrapper #Rect__Modal-Sketch {
  display: none;
}
.Area__Rect {
  padding: 5px;
  background: #fff;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  border: 3px solid;
  opacity: 0.85;
}

.Area__Rect.heatsend {
  background-image: url('/icons/camera.svg');
  background-size: 100%;
}
.Area__PercentText {
  color: #000;
  opacity: 1;
}
.Area__Rect__Detail {
  opacity: 0;
  pointer-events: none;
  transform: translate(-20px, -50%);
  position: absolute;
  top: 50%;
  width: 200px;
  padding: 10px 18px;
  background-color: #fff;
  border-radius: 5px;
  border: 2px solid #4d83ff;
  z-index: 4;
}
.Area__Rect__Detail p {
  margin-bottom: 6px !important;
}
.Area__Rect__Detail p:last-child {
  margin-bottom: 0 !important;
}
.Rect_Hovered {
  opacity: 1;
  transform: translate(0, -50%);
  z-index: 5;
}
.Rect_Hovered__Parent {
  z-index: 2;
  opacity: 1;
}
.Area__Rect:hover {
  z-index: 3;
  opacity: 1;
}
.Area__Rect:hover .Area__Rect__Detail {
  transform: translate(0, -50%);
  opacity: 1;
}
.Rect__Point {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #caced5;
  padding: 0px 30px;
  height: 55px;
  justify-content: space-between;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
}
.Rect__Point__Text {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.9px;
  color: #8992a5;
}
.Rect__Point__Value {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1.1px;
  color: #242c41;
}

.Camera__Rect__Wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  user-select: none;
}
.Camera__Rect__Wrapper.Rect__Editable {
  pointer-events: fill;
  cursor: crosshair;
}
.Rect__Modal__Content {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #caced5;
  padding: 20px 30px;
}
.Rect__Modal__Content .DepartmentContainer {
  width: 100%;
  margin-top: 20px;
  margin-right: 2%;
  font-size: 14px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #242c41;
  align-items: center;
  margin-bottom: 15px;
}
.Rect__Modal__Content label {
  margin-right: 2%;
  font-size: 14px;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #242c41;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
/* .Rect__Modal__Content label.radio-input input{
    margin: 5px;
} */
.Rect__Modal__Content label.text-input input {
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #caced5;
  padding: 10px 5px;
  width: 100%;
  margin-top: 5px;
}
.Rect__Modal__Content label.text-input {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.Rect__Modal__Content label.text-input.opacity {
  opacity: 0.4;
}

.Rect__Modal__Content .DepName {
  width: 100%;
  margin-bottom: 10px;
  padding: 7px 10px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid rgb(187, 187, 187);
}
.Rect__Modal__Content .DepartmentContainer {
  margin-top: 0px;
}
.Rect__Modal__Footer {
  padding: 15px 30px 20px 30px;
  display: flex;
  justify-content: center;
}
.Camera__Rect {
  background: rgba(21, 88, 233, 0.336);
  border: 2px solid rgba(21, 88, 233, 0.5);
  transition-duration: unset;
  user-select: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.Camera__Rect.Rect__Editable {
  pointer-events: fill;
  cursor: move;
}
.Rect__Modal {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.Rect__Overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}
.Rect__Modal__Container {
  z-index: 55;
  position: absolute;
  max-width: 400px;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 3px;
  border: 1px solid #caced5;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
}
.Container__Title {
  background: #fafbfc;
  border-bottom: 1px solid #caced5;
  padding: 14px 30px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.Rect__Modal__Container .UTimeRangePicker__Wrapper {
  border: none;
  padding-left: 0;
  padding-right: 0;
}
.UTimeRangePicker__Wrapper > * {
  transition-duration: 0s;
}

.ui-resizable-handle {
  position: absolute;
  font-size: 0.1px;
  z-index: 99999;
  display: block;
}
.ui-resizable-e {
  cursor: e-resize;
  width: 7px;
  right: -5px;
  top: 0px;
  height: 100%;
}
.ui-resizable-w {
  cursor: e-resize;
  width: 7px;
  left: -5px;
  top: 0px;
  height: 100%;
}
.ui-resizable-s {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  bottom: -5px;
  left: 0px;
}
.ui-resizable-n {
  cursor: s-resize;
  height: 7px;
  width: 100%;
  top: -5px;
  left: 0px;
}
.ui-resizable-se {
  cursor: se-resize;
  width: 14px;
  height: 14px;
  right: 0;
  bottom: 0;
}

.Funnel__Params {
  color: #525971;
}
.Funnel__Params__Item {
  display: flex;
  max-width: 100px;
  margin: 0 auto;
  padding: 12px 2px;
  border-bottom: 1px solid #caced5;
  align-items: center;
  justify-content: space-between;
}

.Tab__Form {
  padding: 0;
  margin-bottom: 50px;
}
.ReactTable .rt-tbody .rt-td {
  cursor: default;
}
.ReactTable .rt-tbody .rt-td:first-child {
  text-align: left;
  padding-left: 15px;
}

/* Mini Nav -> */
.Mini__Nav {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #d8d8d8;
  padding: 20px 0;
  &.Horizontal {
    flex-direction: row;
    gap: 8px;
    padding: 8px;
  }
}
.Mini__Nav__Buttons {
  width: 90%;
  max-width: 150px;
  padding: 0 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 15px;
}
.Mini__Nav__Buttons input[type='number'] {
  max-width: 100%;
  text-align: center;
  padding: 0;
  text-indent: 13px;
  margin-right: 9px;
  border-radius: 3px;
  background-color: #ffffff;
  border: solid 1px #caced5;
  padding: 0 0px 0 5px;
  width: 100%;
  margin-top: 0px;
}
.Mini__Nav__Options {
  display: flex;
  margin-bottom: 16px;
}

/* Overlay -> */
.Overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  background: #fff;
  opacity: 0.8;
  z-index: 1;

  @media screen and (max-width: 600px) {
    width: 100vw !important;
    left: 0 !important;
  }
}

.App__Wrapper .Overlay {
  width: calc(100vw - 120px);
  left: 120px;
}

.ContextMenu__Wrapper {
  background: #fff;
  position: absolute;
  z-index: 7;
  box-shadow: 1px 0 11px -2px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  color: #676869;
}
.ContextMenu__Item {
  padding: 8px 5px;
  line-height: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 0.7;
}
.ContextMenu__Item:not(:nth-child(1)) {
  border-top: 1px solid #f2f4f7;
}
.ContextMenu__Item:hover {
  background: #fafbfc;
  opacity: 1;
}
.ContextMenu__Item .icon {
  margin-right: 8px;
}

.ContextMenu__Item + .archive > svg {
  padding: 2.5px 0 2.5px 6px;
  margin-left: 5px;
}

/* Tabs -> */
.react-tabs__tab-list {
  overflow: auto;
  overflow-y: hidden;
  display: flex;
  list-style: none;
  align-items: center;
  // margin: 0 0 30px 0;
  padding: 0;
  border-bottom: 1px solid #caced5;
  column-gap: 30px;
}
.react-tabs__tab {
  display: flex;
  padding: 20px 1px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: #caced5;
  position: relative;
}
.react-tabs__tab.react-tabs__tab--selected {
  color: #525971;
}
.react-tabs__tab:after {
  content: '';
  background-color: #5481f7;
  width: 0;
  height: 2px;
  left: 50%;
  bottom: -1px;
  position: absolute;
}
.react-tabs__tab.react-tabs__tab--selected:after {
  width: 100%;
  left: 0;
}
.react-tabs__tab.react-tabs__tab--disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

/* <- Tabs */

/* Transition ->*/
.fade-entering {
  opacity: 0;
}
.fade-entered {
  opacity: 1;
}
.fade-exiting {
  opacity: 0;
  position: absolute;
  height: 0px;
  overflow: hidden;
}
.fade-exited {
  opacity: 0;
  height: 0px;
  overflow: hidden;
  position: absolute;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

/* Flexbox --> */
.jc-start {
  justify-content: flex-start;
}
.jc-space-b {
  justify-content: space-between;
}
.ai-center {
  align-items: center;
}

.no-border {
  border: none;
}
.PieChart__Header__Card {
  width: 100%;
  display: table;
  min-height: 32px;
  border: 1px solid #0261db;
  text-align: center;
  margin-bottom: 3px;
  border-radius: 3px;
}
.PieChart__Header__Card.store {
  cursor: pointer;
}
.PieChart__Header__Card > h5 {
  display: table-cell;
  vertical-align: middle;
  padding: 3px 0;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
}
.Brand__Homepage__section {
  margin: 10px auto;
  padding: 10px;
}

/* uPrompt --> */
.uPrompt__Wrapper {
  width: 100vw;
  position: fixed;
  height: 100vh;
  z-index: 20;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uPrompt__Container {
  max-width: 500px;
  padding: 15px 20px;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 7;
  border-radius: 3px;
}
.uPrompt__Text {
  margin: 5px 5px 20px 5px;
  padding: 5px 0;
}
.uPrompt__Buttons .uButton.Primary {
  float: right;
}

/* Settings Page -> */
#Settings .FilterForm__Wrapper {
  height: 0px;
  overflow: hidden;
  opacity: 0;
  border-color: transparent;
}
#Settings .App__Header {
  max-height: 66px;
}
#settingsSketchRectSelect .Overlay {
  display: none;
}
/* <- Settings Page */

/* uMulti -> */
.uMulti__Nav {
  display: flex;
  padding: 12px 30px;
  border-bottom: 1px solid #caced5;
}
.uMulti__Title {
  margin: 0 20px;
  min-width: 90px;
  text-align: center;
}
/* <- uMulti */
/* UCropImage -> */
.UCropImage__Wrapper {
  width: auto;
  position: absolute;
  height: auto;
  z-index: 20;
  left: 20px;
  top: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Area__Image * {
  transition-duration: unset;
}
.UCropImage__Wrapper .ReactCrop {
  max-width: 1000px;
}
.UCropImage__Wrapper .ReactCrop img {
  max-width: 1000px;
  height: auto;
  width: auto;
}

.UCropImage__Container {
  width: auto;
  height: auto;
  padding: 15px 20px;
  background: #fff;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  z-index: 7;
  border-radius: 3px;
}
.Image__Cropper__Buttons {
  margin: 10px 0 15px 0;
}
.Image__Cropper__Buttons > div {
  display: inline-flex;
  margin-left: 15px;
}
/* <- UCropImage -/

/* Mobile -> */
@media only screen and (max-width: 1200px) {
  .Chart__Wrapper {
    min-height: 200px;
    height: auto;
  }
}
@media only screen and (max-width: 990px) {
  Border__Right,
  .Border__Left {
    margin: 0 15px;
    padding: 0;
  }
  .Border__Left {
    border-top: 1px solid #caced5;
  }
  .Border__Right {
    border-bottom: 1px solid #caced5;
  }
  .Border__Right:after,
  .Border__Left:after {
    display: none;
  }
}
.googleMapContainer,
.googleMapContainer * {
  transition-duration: unset;
}
.destroy {
  display: contents;
}
.closeItem {
  cursor: pointer;
  float: right;
}
.top {
  margin-top: 30px;
}
.containerC {
  width: 100%;
}

/*browser detection*/
.IE11 .Login__Wrapper .Logo__Login {
  top: 0 !important;
}
.IE11 .Company__Logo {
  position: relative;
  padding: 0 !important;
  width: 120px;
  height: 40px;
  margin-top: 20px;
}
.IE11 #Area .bordered-content {
  display: inline-table;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  /* margin-left: 20px; */
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.Bubble__Wrapper {
  width: 100%;
  height: auto;
  padding: 5px;
  display: grid;
  grid-auto-columns: 1fr;
  margin-top: 55px;
}

.Bubble__İtem {
  display: inline-block;
  background-image: linear-gradient(174deg, #267ef1, #1446d1);
  border-radius: 10px;
  color: white;
  margin: 5px;
  padding: 5px 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}
.Bubble__Group__Head {
  min-width: 100px;
  text-align: center;
  border-radius: 10px;
  background-image: linear-gradient(174deg, #4794f8, #3a5fc5);
}
.Bubble__İtem:hover {
  background-image: linear-gradient(174deg, #2470d3, #0f3bb3);
}
.Bubble__item__active {
  background-image: linear-gradient(174deg, #9fa0a1, #4d4d4d);
  color: rgb(206, 206, 206);
}
.Bubble__item__active:hover {
  background-image: linear-gradient(174deg, #a7a9aa, #616060);
}
.Bubble__item__active > span::before {
  content: '';
  width: 100%;
  height: 1px;
  position: relative;
  top: 13px;
  left: 0;
  background-color: white;
  z-index: 2;
  display: block;
}
/*  */
/* radio buton css */

/* Hide the browser's default radio button */
input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  height: 20px;
  width: 20px;
  min-width: 20px;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: 2px solid #d0cece;
  margin-left: 0;
  margin-right: 10px;
}
.App__Header .UDropdown__Wrapper {
  // display: block;
  float: left;
}
.App__Header label:hover .checkmark:after {
  opacity: 1 !important;
}
input[type='radio']:checked + .checkmark:after {
  background: #5481f7 !important;
  opacity: 1 !important;
}

input[type='radio']:checked + .checkmark {
  border: 2px solid #5481f7;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
}

/* Style the indicator (dot/circle) */
.checkmark:after {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #d0cece;
  margin: auto;
  opacity: 0;
}

.checkmark.disabled {
  pointer-events: none !important;
  opacity: 0.3 !important;
  cursor: not-allowed !important;
}

.radio-input[disabled],
.radio-input[disabled] > * {
  cursor: not-allowed;
}

.heightAuto {
  height: auto !important;
}
.scaleDiv {
  overflow: hidden;
}
.scaleDivInner {
  transform-origin: top left;
  float: left;
}
.scaleDivInner2 {
  transform-origin: top left;
}
.scaleDivInnerImg {
  transform-origin: top left;
  float: left;
}
.scaleDivInner img {
  transform-origin: top left;
}
.zIndex {
  z-index: 11;
}
.pageDropdown .UDropdownItem__Wrapper {
  min-width: 664px;
}
.transformDiv {
  transform-origin: top left;
}
#area .areaContainer {
  margin-bottom: 20px;
}
.New-Overlay {
  display: none;
}
.Overlayv2 {
  display: block;
}
@media screen and (max-width: 1380px) {
  #Charts th {
    font-size: 12px;
  }
  .rt-resizable-header-content {
    font-size: 11px !important;
    line-height: 20px !important;
  }
  .ReactTable .rt-tbody .rt-td {
    font-size: 11px !important;
  }
  .Area__Image {
    margin-bottom: 0 !important;
  }
  .PageContent__Container {
    padding: 10px 20px;
  }
  .Overlayv2 .elementTitle {
    padding-top: 0 !important;
  }
}

.searchImgButton {
  width: 23px;
  height: 23px;
  margin: 0 0 3px 2px;
  padding: 5px;
}

.StickyHeaderItems {
  padding-top: 10px;
  padding-bottom: 10px;
  position: sticky;
  top: 63px;
  z-index: 1;
  background: white;
  margin-bottom: 20px;
  height: auto;
}
/* Brandchart title begin */
.Chart_center_header_title {
  text-align: center;
  border-bottom: 1px solid #caced5;
  padding: 3px 0px;
  // margin-bottom: 1.5vh;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.Error_message {
  color: #ccc;
  font-size: 1em;
  text-align: center;
  &.danger {
    color: var(--errorButtonBg);
  }
}

/* Brandchart title end  */
.Default__Not__Found {
  margin-bottom: -75px;
}

.Default__Not__Found > img {
  max-width: 600px;
  width: 80%;
}

.Black__UnderLine:hover {
  text-decoration: underline;
}
.Black__UnderLine {
  display: inline;
}

.Display_Grid {
  display: grid;
}

/* React Table */
.ReactTable .rt-noData {
  z-index: -1;
}

.ReactTable .rt-tbody .ClickableTableLabel:hover {
  text-decoration: underline;
  cursor: pointer;
}

/*    */

.DateTimeRangeAlign {
  margin-left: calc(100% - calc(100% - 210.531px));
}
.ReactTable .rt-th .searchImg {
  position: relative;
  left: 125px;
}
.ComparisonDateTimeRangeAlign {
  width: calc(100% - 221.531px);
  display: inline-block;
}

/* rechart tooltip styles  */
.EntraceCountChart .recharts-tooltip-wrapper,
.sugBars .recharts-tooltip-wrapper {
  background-color: rgb(255, 255, 255);
  border: 0.15px solid rgb(233, 233, 233);
}
.EntraceCountChart .recharts-default-tooltip,
.sugBars .recharts-default-tooltip {
  margin: 10px;
}
.EntraceCountChart .recharts-tooltip-label,
.sugBars .recharts-tooltip-label {
  margin: 3px;
}
.recharts-legend-ul {
  padding: 0;
}

.recharts-legend-li {
  display: inline;
  font-size: 12px;
  margin-right: 2em;
  list-style: circle;
  cursor: pointer;
}
.recharts-weather-icons {
  margin-left: 5px;
  vertical-align: sub;
}
.heatmap-recharts-tooltip-wrapper {
  background-color: rgb(255, 255, 255);
  border: 0.15px solid rgb(233, 233, 233);
}
.heatmap-recharts-default-tooltip {
  margin: 10px;
}
.heatmap-container {
  min-height: 350px;
}
.UHeatmap {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
}

/*  */

/* zoho */
.siq_bR {
  right: 60px !important;
  transform: scale(0.7);
  bottom: 13px !important;
}
/* */

.Select__Hidden__Input {
  opacity: 0;
  width: 100%;
  height: 0;
  bottom: 15px;
  position: relative;
}

.lolipop {
  cursor: pointer;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  margin-left: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #0078d7;
}
.store_Name {
  cursor: pointer;
}
.archivedMessage {
  font-size: 12px;
  color: gray;
  margin-left: 12px;
  vertical-align: super;
}

.star {
  display: inline-block;
  font-size: 30px;
}
.star::before {
  content: '★';
  letter-spacing: 3px;
  background: linear-gradient(to bottom, #e4d51f, #f5a623);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.commentWarning,
.commentWarning::before,
.commentWarning::after {
  position: relative;
  padding: 0;
  margin: 0;
}
.commentWarning {
  font-size: 17px;
}

.commentWarning {
  display: inline-block;
  width: 1.1em;
  height: 1.1em;
  border-radius: 60%;
}

.commentWarning::before {
  content: '';
  display: block;
  top: 0.3em;
  left: 0em;
  position: absolute;
  border: transparent 0.55em solid;
  border-bottom-width: 1em;
  border-top-width: 0;
  box-shadow: #999 0 1px 1px;
  border-radius: 50%;
  background-color: #0458fe;
}
.commentWarning::after {
  display: block;
  position: absolute;
  top: 0.2em;
  left: 0;
  width: 100%;
  padding: 0 1px;
  text-align: center;
  content: '!';
  color: white;
  font-size: 0.9em;
}
.clickable {
  cursor: pointer;
}

.customTooltip {
  position: relative;
  &:hover .tooltipHiddenContent {
    visibility: visible;
    user-select: all;
    * {
      visibility: visible;
    }
  }
  .tooltipHero {
  }
  .tooltipHiddenContent {
    display: flex;
    user-select: none;
    position: absolute;
    visibility: hidden;
    transition-duration: 1s;
    right: 0;
    // left: 50%;
    // transform: translate(-50%, 0);
    background-color: white;
    padding: 10px;
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
    & > * {
      display: inline-block;
    }
    * {
      visibility: hidden;
      transition-duration: 1s;
    }
  }
}
.excelexportContainer {
  margin: 0 5px;
  cursor: pointer;
  height: 100%;
  text-align: center;
  &:hover {
    opacity: 0.6;
  }
  .download  {
    margin: auto;
    display: block;
    width: 22px;
    height: 22px;
  }

  p {
    font-size: 0.5rem;
    text-align: center;
    margin-bottom: 0;
  }
}

.campaignInfoHeader {
  display: inline-flex;
  cursor: pointer;
  &:hover .campaignHoverText {
    visibility: visible;
  }
  .info {
    position: relative;
    bottom: 9px;
    font-size: 18px;
  }
  .campaignHoverText {
    font-size: 15px !important;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    border: solid #707070;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.16);
    visibility: hidden;
    opacity: 1;
    background-color: #fff;
    color: #242c41;
    position: absolute;
    z-index: 10;
    padding: 5px;
    transition-duration: 0s;
  }
}

.CustomLegendContent {
  .CollapseLegend {
    max-height: 120px;
    overflow-y: scroll;
    &.expand {
      max-height: 205px;
      overflow: unset;
    }
  }
  .showButtonContainer {
    text-align: end;
    cursor: pointer;
  }

  .child {
    cursor: pointer;
  }
}

.unselectable {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in Internet Explorer 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}
.UErrorLink {
  font-weight: bold;
}

.UErrorLink:hover {
  opacity: 0.6;
  cursor: pointer;
}
.MaxContent_Title {
  width: max-content;

  @media screen and (max-width: 600px) {
    width: auto;
  }
}
.archivedCameraTitle {
  color: gray;
}
.CameraArchIcon {
  padding: 10px;
}
.CameraArchIcon.ArchivedDataicon::before {
  width: 15px;
  height: 15px;
}
input:checked + .slider.status {
  background-color: green;
}

.icon.favicon {
  background-image: url('/icons/favicon.svg');
  width: 18px;
  height: 18px;
}

.resetted-button-styles {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}
